
@font-face {
    font-family: 'KoHo';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
    url('/layout/fonts/koho-v16-latin-regular.woff2') format('woff2'),
    url('/layout/fonts/koho-v16-latin-regular.woff') format('woff');
}

@font-face {
    font-family: 'KoHo';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
    url('/layout/fonts/koho-v16-latin-600.woff2') format('woff2'),
    url('/layout/fonts/koho-v16-latin-600.woff') format('woff');
}

@font-face {
    font-family: 'KoHo';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
    url('/layout/fonts/koho-v16-latin-700.woff2') format('woff2'),
    url('/layout/fonts/koho-v16-latin-700.woff') format('woff');
}
